<template>
  <div class="article-box" v-loading="loading">
    <!-- 导航 -->
    <div class="page-nav clearfix">
      <ul>
        <li class="nav-item">会员管理&nbsp;&nbsp;/</li>
        <li class="nav-item nav-item-last">&nbsp;&nbsp;单位会员管理</li>
      </ul>
    </div>
    <!-- 标题 -->
    <div class="pag-title clearfix">
      <div class="page-title-name pull-left">单位会员管理</div>
    </div>
    <!-- 搜索栏 -->
    <div class="search-box clearfix">
      <!-- <div class="search-label pull-left">名称或账号:</div> -->
      <div class="search-item pull-left">
        <el-input
          v-model="queryData.keyword"
          clearable
          size="small"
          style="width: 200px; height: 32px"
          placeholder="请输入单位全称或账号"
        >
        </el-input>
      </div>
      <div class="search-label pull-left">会员等级:</div>
      <div class="search-item pull-left">
        <el-select
          v-model="queryData.plenaryLevel"
          clearable
          size="small"
          style="width: 100px; height: 32px"
        >
          <el-option
            v-for="item in plenaryLevel"
            :key="'plenaryLevel' + item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="search-label pull-left">本机构等级:</div>
      <div class="search-item pull-left">
        <el-select
          v-model="queryData.orgLevel"
          clearable
          size="small"
          style="width: 100px; height: 32px"
        >
          <el-option
            v-for="item in orgLevel"
            :key="'orgLevel' + item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="button-item button-search pull-left" @click="searchHandle">
        查询
      </div>&nbsp;
      <div class="button-item button-down " @click="handleExport">
        导出企业类会员
      </div>&nbsp;
      <div class="button-item button-down " @click="handleExport1">
        导出社团类会员
      </div>
    </div>
    <!-- 表格 -->
    <el-table border :data="listData" highlight-current-row header-row-class-name="table-header-class" :header-cell-style="{ 'background-color': '#F7F8FA' }"
    style="width: 100%; margin-top: 20px;" >
      <el-table-column width="50" type="index" label="序号" ></el-table-column>
      <el-table-column prop="loginName" label="账号（企业信用代码）" ></el-table-column>
      <el-table-column prop="unitNickName" label="单位全称" ></el-table-column>
      <el-table-column prop="" label="大会等级" >
        <template slot-scope="scope">
          {{ plenaryLevel2[scope.row.plenaryLevel] || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="本机构等级" >
        <template slot-scope="scope">
          {{ plenaryLevel2[scope.row.orgLevel] || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="initiationTime" label="入会时间" ></el-table-column>
      <el-table-column prop="payFeeExpireTime" label="会费到期时间" >
        <template slot-scope="scope">
          {{ scope.row.payFeeExpireTime + '-12-31' }}
        </template>
      </el-table-column>
      <el-table-column prop="certificateExpireTime" label="证书到期时间" >
        <template slot-scope="scope">
          {{ scope.row.certificateExpireTime + '-12-31' }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作" width="250">
        <template slot-scope="scope" >
          <div class="clecrfix">
            <div class="option-item pull-left option-normal" @click="getDetail(scope.row.id, scope.row.userId)" >
              详情
            </div>
            <div class="option-item pull-left option-normal" @click="setLevel(scope.row.id, scope.row.orgLevel)" >
              设置机构等级
            </div>
            <div class="option-item pull-left option-err" @click="removeInitiation(scope.row.id)" >
              移出机构
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: right; padding-top: 20px"
      @size-change="pageSizeHandle"
      @current-change="pageChangeHandle"
      :page-sizes="[10, 20, 30, 40, 50, 60, 70, 80]"
      layout="total, sizes, prev, pager, next, jumper"
      :page-size="pageSize"
      :current-page="pageNo"
      :page-count="totalPage"
      :total="totalCount"
    >
    </el-pagination>
    <!-- <div class="table-box" v-if="totalCount">
      <table>
        <tbody>
          <tr v-for="(item, index) in listData" :key="item.id">
            <td>{{ index + 1 }}</td>
            <td>{{ item.loginName || "-" }}</td>
            <td>{{ item.unitNickName || "-" }}</td>
            <td>{{ plenaryLevel2[item.plenaryLevel] || "-" }}</td>
            <td>{{ plenaryLevel2[item.orgLevel] || "-" }}</td>
            <td>{{ item.initiationTime || "-" }}</td>
            <td>{{ item.payFeeExpireTime || "-" }}</td>
            <td>{{ item.certificateExpireTime || "-" }}</td>
            <td class="clecrfix">
              
            </td>
          </tr>
        </tbody>
      </table>
      <el-pagination
        style="text-align: right; padding-top: 20px"
        @size-change="pageSizeHandle"
        @current-change="pageChangeHandle"
        :page-sizes="[10, 20, 30, 40, 50, 60, 70, 80]"
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="pageSize"
        :current-page="pageNo"
        :page-count="totalPage"
        :total="totalCount"
      >
      </el-pagination>
    </div> -->
    <!-- <div class="no-data-img" v-if="noData"></div> -->
    <!-- 详情弹框 -->
    <!-- 设置会员等级 -->
    <el-dialog title="会员等级" :visible.sync="isShowSet">
      <p style="margin: 20px">设置该会员单位在本分支机构中的等级为</p>
      <el-radio-group v-model="setType" style="margin-left: 20px">
        <el-radio :label="1">会员</el-radio>
        <el-radio :label="2">理事</el-radio>
        <el-radio :label="3">常务理事</el-radio>
        <el-radio :label="4">副会长 </el-radio>
      </el-radio-group>
      <div style="margin: 20px" class="clearfix">
        <div
          class="button-item button-search pull-right"
          @click="sureSetLevel()"
          style="margin-left: 20px"
        >
          确定
        </div>
        <div
          class="button-item button-cancle pull-right"
          @click="cancelSetLevel()"
        >
          取消
        </div>
      </div>
    </el-dialog>
    <!-- 移出机构 -->
    <el-dialog title="移出机构" :visible.sync="dialogVisible" width="30%">
      <p style="margin: 20px">确定将该会员单位移出机构？</p>
      <div style="margin: 20px" class="clearfix">
        <div
          class="button-item button-search pull-right"
          @click="sureRemove()"
          style="margin-left: 20px"
        >
          确定
        </div>
        <div
          class="button-item button-cancle pull-right"
          @click="dialogVisible = false"
        >
          取消
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  InstitutionUnitMember,
  InstitutionUnitMemberBase,
  InstitutionUnitMemberDetail,
  InstitutionUnitMemberInfo,
  InstitutionUnitMemberSetLevel,
  InstitutionUnitMemberDel,
} from "@/http/api/InstitutionApi";
// import { downloadFile } from '@/http/request';
import {
  showPlenaryLevel,
  showOrgLevel,
  showUnitNature,
  showIndustryNature,
  showSex,
} from "@/utils/util.ts";
import { FileDownload, FileDownload2 } from "@/http/api/FileApi";
export default {
  components: {},
  data() {
    return {
      listData: [],
      queryData: {
        keyword: "",
        plenaryLevel: "",
        orgLevel: "",
      },
      noData: false,
      totalPage: 0,
      totalCount: 0,
      pageNo: 1,
      pageSize: 10,
      plenaryLevel2: ["", "会员", "理事", "常务理事", "副会长", "会员代表"],
      // 大会等级
      plenaryLevel: [
        { label: "会员", value: 1 },
        { label: "会员代表", value: 5 },
        { label: "理事", value: 2 },
        { label: "常务理事", value: 3 },
        { label: "副会长", value: 4 },
      ],
      // 本机构等级
      orgLevel: [
        { label: "会员", value: 1 },
        { label: "理事", value: 2 },
        { label: "常务理事", value: 3 },
        { label: "副会长", value: 4 },
      ],
      loading: false, //页面加载
      itemId: "",
      itemUserId: "",
      isShowDetail: false, // 详情弹框展示
      activeName: "first", // 详情tab
      itemDetailBase: {},
      itemDetail: {},
      itemDetailInfo: {},
      detailLoading: false,
      isShowSet: false,
      setType: "",
      setId: "",
      dialogVisible: false,
      delId: "",
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleExport() {
      FileDownload2("/member/i/org/member/unit/community/export", {
        plenaryLevel: this.queryData.plenaryLevel,
      });
    },
    handleExport1() {
      FileDownload2("/member/i/org/member/unit/enterprise/export", {
        plenaryLevel: this.queryData.plenaryLevel,
      });
    },
    getList() {
      this.loading = true;
      var item = {};
      item.type = 2;
      item.state = 2;
      item.keyword = this.queryData.keyword || "";
      item.pageNo = this.pageNo || 1;
      item.pageSize = this.pageSize || 10;
      if (this.queryData.plenaryLevel) {
        item.plenaryLevel = this.queryData.plenaryLevel;
      }
      if (this.queryData.orgLevel) {
        item.orgLevel = this.queryData.orgLevel;
      }
      InstitutionUnitMember(item).then((resp) => {
        this.loading = false;
        if (resp.code === "000") {
          this.totalPage = resp.result.pages;
          this.totalCount = resp.result.total;
          this.pageNo = resp.result.current;
          this.listData = resp.result.records;
          if (!resp.result.total) {
            this.noData = true;
          }
        } else {
          this.$Message.error("获取数据失败");
        }
      });
    },
    searchHandle() {
      this.pageNo = 1;
      this.getList();
    },
    resetHandle() {},
    getDetail(id, uid) {
      this.$router.push({
        path: "/institution/member/unit/detail",
        query: { id: id, pid: uid },
      });
      // this.itemId = id;
      // this.itemUserId = uid,
      // this.detailLoading = true;
      // this.activeName = 'first';
      // this.isShowDetail = true;
      // InstitutionUnitMemberBase({id: this.itemUserId}).then(resp => {
      //     this.detailLoading = false;
      //     if (resp.code === '000') {
      //         this.$nextTick(()=>{
      //             this.itemDetailBase = {...resp.result}
      //         })
      //     } else {
      //         this.$Message.error("获取数据失败");
      //     }
      // })
    },
    setLevel(id, type) {
      this.setId = id;
      this.setType = type || "";
      this.isShowSet = true;
    },
    cancelSetLevel() {
      this.setId = "";
      this.setType = "";
      this.isShowSet = false;
    },
    sureSetLevel() {
      InstitutionUnitMemberSetLevel({
        memberId: this.setId,
        orgLevel: this.setType,
      }).then((resp) => {
        if (resp.code == "000") {
          this.isShowSet = false;
          this.getList();
        } else {
          this.$Message.error("操作失败");
        }
      });
    },
    removeInitiation(id) {
      this.delId = id;
      this.dialogVisible = true;
    },
    sureRemove() {
      InstitutionUnitMemberDel({ id: this.delId }).then((resp) => {
        if (resp.code == "000") {
          this.dialogVisible = false;
          this.getList();
        } else {
          this.$Message.error("操作失败");
        }
      });
    },
    tabChange(tab, event) {
      if (tab.index == 0) {
        this.activeName = "first";
      } else if (tab.index == 1) {
        this.detailLoading = true;
        this.activeName = "second";
        InstitutionUnitMemberDetail({ id: this.itemId }).then((resp) => {
          this.detailLoading = false;
          if (resp.code === "000") {
            this.itemDetail = { ...resp.result };
            this.itemDetail.unitEnterpriseVo =
              { ...resp.result.unitEnterpriseVo } || {};
            this.itemDetail.unitCommunityVo =
              { ...resp.result.unitCommunityVo } || {};
          } else {
            this.$Message.error("获取数据失败");
          }
        });
      } else if (tab.index == 2) {
        this.detailLoading = true;
        this.activeName = "third";
        InstitutionUnitMemberInfo({ id: this.itemId }).then((resp) => {
          this.detailLoading = false;
          if (resp.code === "000") {
            this.itemDetailInfo = { ...resp.result };
            this.itemDetailInfo.memberForResumes =
              { ...resp.result.memberForResumes } || [];
            this.itemDetailInfo.unitReprsts =
              { ...resp.result.unitReprsts } || [];
          } else {
            this.$Message.error("获取数据失败");
          }
        });
      }
    },
    // 分页函数
    pageSizeHandle(val) {
      this.pageNo = 1;
      this.pageSize = val;
      this.getList();
    },
    pageChangeHandle(val) {
      this.pageNo = val;
      this.getList();
    },

  },
};
</script>
<style lang="less" scoped>
.no-data-img {
  width: 100%;
  height: 300px;
  background: url("../../assets/images/nodata.png") no-repeat center center;
}
/deep/.el-dialog__body {
  padding: 5px 20px;
}
.first-tab ul {
  width: 100%;
}
.first-tab ul li {
  display: inline-block;
  width: 50%;
  line-height: 32px;
  .datail-label {
    display: inline-block;
    width: 40%;
    text-align: right;
    padding-right: 10px;
  }
  .datail-text {
    display: inline-block;
    width: 60%;
    text-align: left;
  }
}
.first-tab ul .one-line {
  width: 100%;
  .datail-label {
    width: 20%;
  }
  .datail-text {
    width: 80%;
  }
}
.first-tab ul .one-third-line {
  width: 33%;
  .datail-label {
    width: 40%;
  }
  .datail-text {
    width: 60%;
  }
}
.datail-title {
  font-size: 16px;
  font-weight: 700;
  margin: 20px;
}

</style>
